<script lang="ts" setup>
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import type { Menu } from '~/types/api'
import { getBreakpointValue } from '~/utils/breakpoint'
import LogoSvg from '~/assets/images/logo.svg?component'

const runtimeConfig = useRuntimeConfig()
const menusState = useMenusState()

const siteName = computed(() => {
    return runtimeConfig.public.siteName
})

const mainMenu = computed(() => {
    return menusState.value?.find((menu: Menu) => menu.slug === 'main-menu')
})

function onResize() {
    if (window.innerWidth > getBreakpointValue('vl')) menuIsOpen.value = false
}

function onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape') closeMenu()
}

const menuIsOpen = useTopBarMenu()
const toggleMenu = () => (menuIsOpen.value = !menuIsOpen.value)

const root = ref<HTMLElement | null>(null)

const openMenu = () => {
    const target = root.value as HTMLElement

    disableBodyScroll(target, {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => {
            while (el && el !== document.body) {
                if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                    return true
                } else if (el.parentElement) el = el.parentElement
            }

            return false
        },
    })

    window.addEventListener('keyup', onKeyUp)
    menuIsOpen.value = true
}

const closeMenu = () => {
    const target = root.value
    target && enableBodyScroll(target)

    window?.removeEventListener('keyup', onKeyUp)
    menuIsOpen.value = false
}

watch(menuIsOpen, (value) => {
    if (value) openMenu()
    else closeMenu()
})

const route = useRoute()
watch(route, () => {
    closeMenu()
})

onMounted(() => {
    // if component is unmount without enableBodyScroll
    clearAllBodyScrollLocks()
    closeMenu()
    window.addEventListener('resize', onResize)
})

onBeforeUnmount(() => {
    menuIsOpen.value && window.removeEventListener('keyup', onKeyUp)
    window.removeEventListener('resize', onResize)
})
</script>

<template>
    <div ref="root" :class="[$style.root, menuIsOpen && $style['root--menu-open']]">
        <div :class="$style.inner">
            <NuxtLink :class="$style.home" :title="siteName" to="/">
                <LogoSvg :class="$style.logo" viewBox="0 0 132 25" :alt="siteName" :title="siteName" />
            </NuxtLink>

            <Transition :name="$style['menu-transition']">
                <div v-show="menuIsOpen" :class="$style.menu">
                    <div :class="$style['menu__inner']" body-scroll-lock-ignore="true">
                        <slot>
                            <VMenu v-if="mainMenu" :class="$style['main-menu']" :menu="mainMenu" />
                            <!-- Need to display a empty div for CSS grid -->
                            <div v-else></div>
                        </slot>

                        <VSession :class="$style.session" />
                    </div>
                </div>
            </Transition>

            <VButton
                :class="$style['menu-toggle']"
                filled
                theme="yellow"
                :aria-label="$t('toggle_menu')"
                @click="toggleMenu"
            >
                <template #icon>
                    <SvgIcon
                        :name="menuIsOpen ? 'burger-close' : 'burger-menu'"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    />
                </template>
            </VButton>
        </div>
    </div>
</template>

<style lang="scss" module>
.menu-transition {
    &:global(#{'-enter-active'}),
    &:global(#{'-leave-active'}) {
        transition:
            translate 0.5s,
            opacity 0.5s;
        translate: 0;
    }

    &:global(#{'-enter-from'}),
    &:global(#{'-leave-to'}) {
        translate: 0 -100%;
    }
}

.root {
    position: relative;
    z-index: 10;
    display: flex;
    height: $v-top-bar-s;
    align-items: center;

    &::before {
        position: fixed;
        background-color: color(black-40);
        content: '';
        inset: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s;
    }

    &--menu-open::before {
        opacity: 1;
    }

    @include media('>=vl') {
        height: $v-top-bar-m;
        padding: 0 calc(var(--gutter) * 0.5);
    }
}

.inner {
    display: grid;
    width: 100%;
    align-items: center;
    gap: var(--spacing-2-xs, 16px);
    grid-template-columns: auto auto;

    @include media('>=vl') {
        justify-content: space-between;
        border-radius: 12px;
        background-color: color(white);
        grid-template-columns: 1fr auto 1fr;
    }
}

.home {
    z-index: 101;
    display: block;
    width: min-content;
    padding: 12px;
    border-radius: 6px;
    margin: 12px;
    background-color: var(--color-surfaces-surface-dark);
}

.logo {
    display: block;
    width: 105px;
    height: 20px;
}

.menu {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;

    @include media('>=vl') {
        position: relative;
        display: grid !important;
        height: 100%;
        flex-grow: 1;
        gap: var(--spacing-2-xs, 16px);
        grid-column: 2 / -1;
        grid-template-columns: subgrid;
    }
}

.menu__inner {
    display: flex;
    height: 100svh;
    flex-direction: column;
    padding: rem(75 + 64) rem(24) rem(36 + 32);
    background-color: color(surfaces-secondary);
    gap: rem(90);
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    touch-action: pan-y;

    @include media('>=vl') {
        display: contents;
    }
}

.main-menu {
    --v-menu-gap: #{rem(20)};
    --v-menu-justify-content: center;

    ul {
        height: 100%;
    }

    @include media('<vl') {
        --v-menu-link-underline-height: 1px;
        --v-menu-link-underline-color: #{color(black)};
        --v-menu-link-underline-active-color: #{color(black)};

        ul a {
            @include text-menu-xl;
            text-align: center;
        }
    }

    @include media('>=vl') {
        --v-menu-gap: #{rem(40)};
        --v-menu-flex-direction: row;
        --v-menu-align-items: stretch;

        ul a {
            height: 100%;
        }
    }
}

.session {
    min-height: 60px;
    flex-direction: column;
    justify-content: center;
    margin: 0 12px;

    & > a,
    & > button {
        flex-shrink: 0;
    }

    @include media('<vl') {
        --v-menu-link-underline-height: 1px;
        --v-menu-link-underline-color: #{color(black)};
        --v-menu-link-underline-active-color: #{color(black)};
    }

    @include media('>=vl') {
        height: 100%;
        flex-direction: row;
        justify-content: flex-end;
    }
}

.menu-toggle {
    --v-button-filled-background-color: #{color(white)};
    --v-button-height: #{rem(40)} !important;
    --v-button-min-width: #{rem(40)} !important;

    z-index: 101;
    display: inline-flex;
    margin: 12px;
    cursor: pointer;
    justify-self: flex-end;

    @include media('>=vl') {
        display: none !important;
    }
}
</style>
