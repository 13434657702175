<script lang="ts" setup>
import type { Menu } from '~/types/api'

const runtimeConfig = useRuntimeConfig()
const menusState = useMenusState()

const footerTitle = computed(() => runtimeConfig.public.footer.title)
const siteName = computed(() => runtimeConfig.public.siteName)
const footerText = computed(() => runtimeConfig.public.footer.text)

const { facebookURL, twitterURL, youtubeURL, instagramURL, linkedinURL } = runtimeConfig.public.footer
const socials = computed(() => {
    const socials = [
        {
            url: facebookURL,
            title: 'Facebook',
            icon: 'facebook',
        },
        {
            url: twitterURL,
            title: 'X (Twitter)',
            icon: 'x',
        },
        {
            url: instagramURL,
            title: 'Instagram',
            icon: 'instagram',
        },
        {
            url: linkedinURL,
            title: 'LinkedIn',
            icon: 'linkedin',
        },
        {
            url: youtubeURL,
            title: 'Youtube',
            icon: 'youtube',
        },
    ]
    // Remove socials without url (DotEnv empty string)
    return socials.filter((social) => social.url)
})
const linksMenu = computed<Menu | undefined>(() => {
    return menusState.value?.find((menu: Menu) => menu.slug === 'liens-utiles')
})
const rentalMenu = computed<Menu | undefined>(() => {
    return menusState.value?.find((menu: Menu) => menu.slug === 'location-vente')
})
const lastFooterMenu = computed<Menu | undefined>(() => {
    return menusState.value?.find((menu: Menu) => menu.slug === 'footer')
})
</script>

<template>
    <footer :class="$style.root">
        <div :class="[$style.footer, $style['footer-grid']]">
            <div>
                <SvgIcon :class="$style.logo" name="logo" :alt="siteName" :title="siteName" viewBox="0 0 132 25" />
                <div :class="$style.socials">
                    <a
                        v-for="social in socials"
                        :key="social.title"
                        :title="social.title"
                        target="_blank"
                        rel="nofollow noopener"
                        :href="social.url"
                    >
                        <SvgIcon :name="social.icon" viewBox="0 0 24 24" width="24" height="24" />
                    </a>
                </div>
            </div>

            <VMenu :class="$style['nav-list__item']" show-title :title-class="$style.title" :menu="linksMenu" />
            <VMenu :class="$style['nav-list__item']" show-title :title-class="$style.title" :menu="rentalMenu" />
            <section :class="$style['nav-list__item']">
                <div :class="$style.title">{{ footerTitle }}</div>
                <VMarkdown
                    v-if="footerText"
                    :class="$style['footer-text']"
                    class="text-body-m"
                    inline
                    :content="footerText"
                />
            </section>

            <div :class="$style['network-member']">
                <p :class="$style['network-member__title']">{{ $t('network_member') }}</p>
                <SvgIcon :class="$style['network-member__eurlirent']" name="eurlirent" viewBox="0 0 92 18" />
            </div>

            <SvgIcon
                :class="$style.filigrane"
                name="logo-full-outline"
                width="1550"
                height="295"
                viewBox="0 0 1550 295"
            />
        </div>

        <VMenu v-if="lastFooterMenu" rel="nofollow" :class="$style['last-menu']" :menu="lastFooterMenu" />
    </footer>
</template>

<style lang="scss" module>
.root {
    padding: var(--spacing-xl) calc(var(--gutter) * 0.5) var(--spacing-md);

    @include media('>=md') {
        padding-top: var(--spacing-md);
    }
}

.footer {
    --v-menu-link-underline-height: 1px;
    --color-default-foreground: var(--color-white);
    --theme-foreground-color: var(--color-white);
    --v-button-icon-color: var(--color-white);
    --v-button-color: var(--color-white);
    --v-button-min-width: inherit !important;
    --color-default-background: var(--color-black);

    position: relative;
    display: grid;
    width: 100%;
    padding: rem(48) rem(12);
    border-radius: var(--radius-md);
    background: var(--color-surfaces-surface-dark, #010101);
    color: white;
    gap: rem(48);
    grid-auto-flow: dense;
    justify-items: center;

    @include media('>=md') {
        padding: rem(48) rem(32) 0;
        gap: rem(48) var(--gutter);
        grid-template-columns: repeat(3, 1fr);
        justify-items: inherit;
    }

    @include media('>=vl') {
        padding: rem(64) calc(var(--gutter) * 0.5 + #{flex-grid-value(1, 24)}) 0;
        grid-template-columns: calc((440 / 1289) * 100%) repeat(3, 1fr);
    }
}

.logo {
    display: block;
    width: 131px;
    height: 25px;
    grid-column: 1;

    @include media('<md') {
        margin-inline: auto;
    }
}

.socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: rem(48);
    margin-bottom: rem(24);
    color: var(--color-white);
    gap: 24px;
    grid-column: 1;

    @include media('>=lg') {
        grid-column: 1;
        justify-content: initial;
    }
}

.network-member {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    grid-column: 1 / -1;

    @include media('<md') {
        &::before {
            position: absolute;
            top: rem(-24);
            width: 100%;
            height: 1px;
            background-color: rgb(255 255 255 / 20%);
            content: '';
        }
    }

    @include media('>=md') {
        align-items: inherit;
        grid-column: 1;
        grid-row: inherit;
    }
}

.network-member__title {
    margin-bottom: rem(16);
}

.network-member__eurlirent {
    width: 92px;
    height: 18px;
    color: #767676;
}

.nav-list__item {
    --v-menu-link-underline-opacity: 0.2;
    --v-menu-link-underline-color: #fff;

    position: relative;
    width: 100%;

    &::before {
        position: absolute;
        top: rem(-24);
        width: 100%;
        height: 1px;
        background-color: rgb(255 255 255 / 20%);
        content: '';
    }

    @include media('>=md') {
        --v-menu-align-items: flex-start;

        max-width: 34ch;
        grid-row: auto / span 1;

        &::before {
            display: none;
        }
    }

    @include media('>=md', '<vl') {
        &:nth-child(n + 3 of .nav-list__item) {
            grid-column: 2;
        }
    }

    @include media('>=vl') {
        grid-row: auto / span 2;
    }
}

.footer-text {
    max-width: 50ch;
    margin-inline: auto;
    text-align: center;

    @include media('>=md') {
        margin-inline: inherit;
        text-align: inherit;
    }
}

.filigrane {
    z-index: 2;
    width: 100%;
    height: auto;
    grid-column: 1 / -1;
    opacity: 0.2;
    pointer-events: none;
    translate: 0 min(calc((73 / 275) * 100%), 170px);

    @include media('<md') {
        display: none;
    }
}

.last-menu {
    --v-menu-link-underline-height: 1px;
    --v-menu-flex-direction: row;
    --v-menu-gap: #{rem(12)} var(--spacing-md);
    --v-menu-justify-content: center;
    --v-menu-flex-wrap: wrap;

    margin-top: rem(20);
    margin-inline: rem(24);

    @include media('>=md') {
        margin-top: rem(24);
    }
}

.title {
    @include text-h4;
    @include cap-black-italic;

    margin-bottom: rem(16);
    text-align: center;
    text-transform: uppercase;

    @include media('>=md') {
        margin-bottom: rem(24);
        text-align: inherit;
    }
}
</style>
